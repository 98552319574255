/* eslint-disable no-unused-vars */
import axios from "axios/index";
import { API_BASE } from "../api";

export default store => {
  return {
    $store: store,
    login(user, pass) {
      this.$store.commit("loading", true);
      return axios({
        method: "post",
        url: API_BASE + "/authtoken/",
        data: { username: user, password: pass }
      })
        .then(response => {
          this.$store.dispatch('login', response.data.token)
          return response.data;
        })
        .catch(error => {
          throw error;
        });
    },
    logout() {
      this.$store.commit("logout");
    }
  };
};
