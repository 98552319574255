import Vue from 'vue'
import App from './App.vue'
import Api from "./services/api";
import router from "./router";
import store from "./store";
import "@/assets/css/app.scss";
import i18n from './lang'
import VueApexCharts from 'vue-apexcharts'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts)

Vue.use(Api, { store });
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");