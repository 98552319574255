import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'

import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import HelloWorld from '@/components/HelloWorld.vue'
import Error404 from '@/components/Error404.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/welcome',
    name: 'Hello',
    component: HelloWorld,
    meta: {
      auth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    // will match everything
    path: '*',
    component: Error404,
    meta: {
      auth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    if (!store.getters.authenticated) {
      next()
    } else {
      next({
        path: '/'
      })
    }
  }

  else if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters.authenticated) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {

//     console.log(to.matched.some(record => record.meta.requiresAuth))
//     if (!store.getters.authenticated) {
//       next({
//        path: '/login',
//         params: { nextUrl: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     if (!store.getters.authenticated) {
//       next({
//         path: '/',
//         params: { nextUrl: '/' }
//       })
//     } else {
//       next()
//     }
//   }
// })

export default router;
