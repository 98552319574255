import axios from 'axios/index'
import {
  dispatchError,
  API_BASE,
  config as ApiConfig
} from '../api'

export default (store) => {
  return {
    $store: store,
    getUniqueTags () {
      this.$store.commit('loading', true)
      return axios(Object.assign(ApiConfig(this.$store), {
        method: 'get',
        url: API_BASE + `/uniquetagsreads/`
      })).then(res => {
        return res.data
      }).catch(err => {
        dispatchError(this.$store, err.message)
        throw err
      })
    },
    getTotalTags () {
      this.$store.commit('loading', true)
      return axios(Object.assign(ApiConfig(this.$store), {
        method: 'get',
        url: API_BASE + `/totaltagsreads/`
      })).then(res => {
        return res.data
      }).catch(err => {
        dispatchError(this.$store, err.message)
        throw err
      })
    }
  }
}
