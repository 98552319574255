<template>
  <div class="page-content">
    <h1>Welcome</h1>
    <v-btn
      class="mb-1"
      dark
      color="primary submit-btn"
      @click="onSubmit"
    >Log Out</v-btn>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods: {
    onSubmit () {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
