const state = {
  uniqueTagsReadsChart: {
    "timeChartData": [{
      "title": "total_unique_tags_reads_title",
      "info": "total_unique_tags_reads_info",
      "x_axis": ["2020/11", "2021/2", "2021/10"],
      "series": [{
        "name": "total_unique_tags_reads_name",
        "type": "line",
        "data": [10, 12, 15]
      }]
    }]
  },
  totalTagsReadsChart: {
    "timeChartData": [{
      "title": "total_tags_reads_title",
      "info": "total_tags_reads_info",
      "x_axis": ["2021/14", "2021/15"],
      "series": [{
        "name": "total_tags_reads_name",
        "type": "line",
        "data": [30, 40]
      }]
    }]
  }
}

const mutations = {
  setuniqueTagsReadsChart: (state, payload) => {
    state.uniqueTagsReadsChart = payload
  },
  settotalTagsReadsChart: (state, payload) => {
    state.totalTagsReadsChart = payload
  }
}

const actions = {
  setuniqueTagsReadsChart ({ commit }, data) {
    commit('setuniqueTagsReadsChart', data)
  },
  settotalTagsReadsChart ({ commit }, data) {
    commit('settotalTagsReadsChart', data)
  }
}

const getters = {
  uniqueTagsReadsChart: state => state.uniqueTagsReadsChart,
  totalTagsReadsChart: state => state.totalTagsReadsChart
}

export default {
  state,
  mutations,
  actions,
  getters
}
