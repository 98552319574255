const state = {
  token: null,
};

const mutations = {
  login: (state, payload) => {
    state.token = payload;
  },
  logout: (state) => {
    state.token = null;
  }
};

const actions = {
  login({ commit }, payload) {
    commit("login", payload);
  },
  logout({ commit }, list) {
    commit("logout", list);
  }
};

const getters = {
  authenticated: state => {
    return state.token !== null;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
