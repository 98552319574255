<template>
<div class="login">
  <div class="login-page" style="min-height: 100vh">
    <div class="logo">
      <a href="#">
        <img src="@/assets/i/logoClaim.png" alt="logo" />
      </a>
    </div>
    <div class="login-bg">
      <div class="row d-flex justify-content-center">
        <div class="col-md-12">
          <div class="login-box">
            <h2>Acceso</h2>
            <v-form class="form" @submit.prevent="onSubmit" ref="form" lazy-validation>
              <div class="form-item">
                <v-text-field
                  ref="username"
                  :label="$t('lang.login.placeholder_username')"
                  outlined
                  type="text"
                  v-model="username"
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
              <div class="form-item">
                <v-text-field
                  ref="password"
                  :label="$t('lang.login.placeholder_password')"
                  outlined
                  type="password"
                  v-model="password"
                  :rules="[rules.required]"
                ></v-text-field>

              </div>
              <v-btn
                class="mb-1"
                dark
                color="primary submit-btn"
                @click="onSubmit"
              >{{$t('lang.login.submit')}}</v-btn>
            </v-form>
          </div>
        </div>
        <v-snackbar
          :color="snackbarColor"
          v-model="snackbar"
          timeout="5000"
          top
          right
        >
          {{ snackbarText }}
        </v-snackbar>
      </div>
    </div>
    <div class="copy-text">
      <p>©2020 Bionix Supplychain Technologies SLU</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      rules: {
        required: v => !!v || 'Field is required'
      },
      snackbar: false,
      snackbarText: '',
      snackbarColor: '#9ac31c'
    }
  },
  mounted () {
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$api.auth
          .login(this.username, this.password)
          .then(() => {
            window.location.href = '/'
          })
          .catch(error => {
            const errorKey = Object.keys(error.response.data)[0]
            if (error.response.data[errorKey][0] === 'Unable to log in with provided credentials.') {
              this.snackbarText = this.$t(`lang.login.invalid_credentials`)
              this.snackbarColor = 'red darken-2'
              this.snackbar = true
            } else {
              this.snackbarText = this.$t(`lang.login.login_error`)
              this.snackbarColor = 'red darken-2'
              this.snackbar = true
            }
          })
      }
    }
  }
}
</script>
