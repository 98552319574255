<template>
  <div class="dashboard">
      <div class="page-content">
          <v-container fluid>
            <h4 class="page-title">{{ $t('lang.dashboard.title') }}</h4>
            <div v-for="(stats, index) in statsData" :key="index">
            <v-row v-for="(chart, index) in stats.seriesData" :key="index" >
              <v-col md="auto">
                <v-card
                    class="mt-5"
                    elevation="10"
                    height="100"
                    width="350"
                >
                    <v-container
                      fluid
                      class="fill-height"
                    >
                    <v-row no-gutters justify="center" align="start">
                      <v-col class="text-left" md="7">
                        <p class="box-title ml-1 mb-0">{{$t(`lang.dashboard.chart.${chart.title}`)}}</p>
                      </v-col>
                      <v-col class="text-center" md="5">
                        <p class="box-stats mb-0">{{getSum(chart.series[index].data)}}</p>
                      </v-col>
                    </v-row>
                    </v-container>
                </v-card>
              </v-col>
              <v-col md="8">
                  <v-card
                    class="mt-5 py-5"
                    elevation="10"
                    height="360"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          color="primary"
                          dark
                          class="tool-tip"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle
                        </v-icon>
                      </template>
                      <span>{{chart.info}}</span>
                    </v-tooltip>
                    <apexchart
                      type="line"
                      height="330"
                      class="mt-2 mx-0"
                      :options="rfidChartOption(chart)"
                      :series="chart.series"
                    ></apexchart>
                  </v-card>
              </v-col>
            </v-row>
            </div>
          </v-container>
      </div>
  </div>
</template>

<script>
import chartMixin from '@/utils/chartMixin'

export default {
  name: 'Dashboard',
  mixins: [chartMixin],
  mounted () {
    this.getData()
  },
  methods: {
    getData() {
      this.$api.chart.getUniqueTags()
      .then((data) => {
        this.uniqueTagsReadsChart = data.chartData
      })
      .catch(error => {
        console.error("Login Error", error)
      })
      this.$api.chart.getTotalTags()
      .then((data) => {
        this.totalTagsReadsChart = data.chartData
      })
      .catch(error => {
        console.error("Login Error", error)
      })
    },
    getSum (data) {
      var total = data.reduce((acc, val) => {
        return acc + val
      }, 0)
      return this.numberWithCommas(total)
    },
    numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  computed: {
    uniqueTagsReadsChart: {
      get () {
        return this.$store.getters.uniqueTagsReadsChart.timeChartData
      },
      set (data) {
        this.$store.dispatch('setuniqueTagsReadsChart', data)
      }
    },
    totalTagsReadsChart: {
      get () {
        return this.$store.getters.totalTagsReadsChart.timeChartData
      },
      set (data) {
        this.$store.dispatch('settotalTagsReadsChart', data)
      }
    },
    statsData: {
      get () {
        return [
          {
            seriesData: this.uniqueTagsReadsChart,
            seriesTitle: 'Unique RFID Tags Reads'
          },
          {
            seriesData: this.totalTagsReadsChart,
            seriesTitle: 'Total RFID Tags Reads'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
