<template>
<div id="app">
  <v-app>
    <v-app-bar v-if="showHeader" color="white" app>
      <v-toolbar-title><h3 class="ml-2 header-title">Supply Chain Digitalization</h3></v-toolbar-title>
      <v-btn
        class="ml-auto"
        dark
        color="#0074FF"
         @click.prevent="logoutButton"
      >Log Out</v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="showHeader"
      app
      class="pt-0"
      color="#232342"
      permanent
      elevation="5"
      width="200"
      :mini-variant.sync="mini"
      mini-variant-width="60"
    >
      <div class="d-flex">
        <img v-if="mini" :src="require('@/assets/i/simbolo.png')" class="img-fluid mini-logo-class pt-3 mx-auto" />
        <img v-if="!mini" :src="require('@/assets/i/logoClaim.png')" class="img-fluid logo-class pt-3 mx-auto" />
      </div>
      <v-list
          dark
          class="pt-5"
        >
          <router-link to="/" class="menu-item">
            <v-list-item class="white--text text--accent-4" link>
                <v-list-item-icon>
                  <v-img :src="require('@/assets/i/icon-dashboard.svg')"/>
                </v-list-item-icon>
                <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
    </v-navigation-drawer>
    <v-main>
      <div @click="mini = true">
        <router-view />
      </div>
    </v-main>
  </v-app>
</div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    mini: true,
    group: null
  }),
  computed: {
    showHeader () {
      return this.$route.name !== 'login'
    }
  },
  methods: {
    logoutButton () {
      this.$store.dispatch('logout')
      window.location.href = '/login'
    }
  }
}
</script>

<style>
</style>
