import Auth from "./modules/auth.js";
import Chart from "./modules/chart.js";
export const API_BASE = process.env.VUE_APP_API_BASE;

export default function Api(store) {
  return {
    baseUrl: API_BASE,
    token: null,
    $store: store,
    auth: Auth(store),
    chart: Chart(store)
  };
}

export function dispatchError(store, error) {
  store.dispatch("globalErrorMessage", {
    message: error,
    type: "error"
  });
}

export function config(store) {
  return {
    mode: "no-cors",
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Token ' + store.state.auth.token
    }
  };
}
