export default {
  computed: {
    rfidChartData () {
      return {
        'series': [
          {
            'data': [76.0, 73.0, 92.0, 30.0, 43.0, 22.0, 93.0, 29.0, 76.0, 73.0, 92.0, 30.0, 83.0, 22.0, 93.0, 29.0, 76.0, 73.0, 92.0, 30.0, 63.0, 22.0, 93.0, 29.0, 76.0, 73.0, 92.0, 30.0, 103.0, 22.0, 93.0, 29.0, 76.0, 73.0, 92.0, 30.0, 51.0, 52.0, 50.0, 53.0, 46.0, 73.0, 92.0, 93.0, 51.0, 52.0, 50.0, 53.0, 51.0, 52.0, 50.0, 59.0],
            'name': 'RFID',
            'type': 'line'
          }
        ],
        'x_axis': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]
      }
    },
    rfidChartData2 () {
      return {
        'series': [
          {
            'data': [10, 25, 44, 49, 27, 23, 27, 38, 40, 55, 44, 49, 27, 33, 47, 38, 35, 44, 49, 27, 53, 57, 38, 29, 10, 55, 44, 49, 57, 53, 57, 38, 10, 55, 44, 49, 57, 53, 57, 38, 55, 44, 49, 57, 53, 57, 38, 89, 49, 57, 53, 57],
            'name': 'RFID',
            'type': 'line'
          }
        ],
        'x_axis': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]
      }
    },
    rfidColors () {
      return ['#66DA26', '#ff3b00', '#FACC82', '#14b7ff', '#0074ff', '#14b7ff', '#FAF582', '#5DC355']
    },
    // rfidChartOption () {
    //   return {
    //     colors: this.rfidColors,
    //     title: {
    //       text: 'Unique Rfid Tag Read',
    //       align: 'left',
    //       offsetX: 10
    //     },
    //     chart: {
    //       height: 350,
    //       type: 'line',
    //       stacked: false
    //     },
    //     dataLabels: {
    //       enabled: false
    //     },
    //     stroke: {
    //       width: this.rfidChartData.series.map(x => {
    //         if (x.type === 'line') {
    //           return 2
    //         } else {
    //           return 1
    //         }
    //       }),
    //       curve: 'smooth'
    //     },
    //     xaxis: {
    //       categories: this.rfidChartData.x_axis
    //     },
    //     yaxis: {
    //       show: false // Y-AXIS SHOW
    //     },
    //     grid: {
    //       yaxis: {
    //         lines: {
    //           show: false
    //         }
    //       },
    //       xaxis: {
    //         lines: {
    //           show: true
    //         }
    //       }
    //     },
    //     legend: {
    //       show: false,
    //       position: 'top',
    //       horizontalAlign: 'left',
    //       offsetX: -15
    //     }
    //   }
    // },
    barNumericChart () {
      return {
        currentSales: 378.987,
        totalSales: 600.000,
        year: 2021
      }
    }
  },
  methods: {
    rfidChartOption (series) {
      return {
        colors: this.rfidColors,
        title: {
          text: this.$t(`lang.dashboard.chart.${series.title}`),
          align: 'left',
          offsetX: 10
        },
        chart: {
          type: 'line',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        xaxis: {
          categories: series.x_axis,
          tickAmount: 10
        },
        yaxis: series.series.map((x, index) => {
          return {
            show: true, // Y-AXIS SHOW
            seriesName: this.$t(`lang.dashboard.chart.${x.name}`),
            opposite: index >= 1,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: this.rfidColors[index]
            },
            labels: {
              style: {
                colors: this.rfidColors[index]
              }
            },
            title: {
              text: this.$t(`lang.dashboard.chart.${x.name}`),
              style: {
                color: this.rfidColors[index]
              }
            }
          }
        }),
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          },
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -15
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => {
                return this.$t(`lang.dashboard.chart.${seriesName}`)
              }
            }
          }
        },
      }
    }
  }
}
